import React from "react";

const Footer = () => {
  return (
    <div className="Footer">
      Hecho desde LATAM con {" "}
      <span role="img" aria-label="purple heart">
        💜
      </span>
    </div>
  );
};

export default Footer;
